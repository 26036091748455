<template>
    <iq-card>
        <template v-slot:body>
            <b-overlay :show="loading">
                <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form @submit.prevent="handleSubmit(saveTaskReport)" @reset.prevent="reset"
                                    enctype="multipart/form-data">
                                <b-row>
                                    <b-col lg="12" sm="12">
                                        <ValidationProvider :name="$t('irrigation_task.task_completion')" vid="task_completion"
                                                            rules="required|min:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="task_completion"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label> {{ $t('irrigation_task.task_completion') }} <span
                                                    class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="taskReport.complete_type_id"
                                                    id="task_status"
                                                    :options="task_status_list"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="defaultNull">
                                                            {{ $t('globalTrans.select') }}
                                                        </b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="12" sm="12">
                                        <ValidationProvider name="Note (En)" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="note"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label> {{ $t('irrigation_task.notef') }} <span
                                                    class="text-danger">*</span>
                                                </template>
                                                <b-form-textarea
                                                    id="note"
                                                    v-model="taskReport.note"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    placeholder="Enter something..."
                                                    rows="3"
                                                    max-rows="6"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="12" sm="12">
                                        <ValidationProvider name="Note (Bn)" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="note_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label> {{ $t('irrigation_task.note_bn') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-textarea
                                                    id="note_bn"
                                                    v-model="taskReport.note_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    placeholder="Enter something..."
                                                    rows="3"
                                                    max-rows="6"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="12" sm="12">
                                        <ValidationProvider name="Date" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="date"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label> {{ $t('irrigation_task.task_calendar_date') }} <span
                                                    class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    class="date-picker"
                                                    v-model="taskReport.task_date"
                                                    placeholder="Select Date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="12" sm="12">
                                        <ValidationProvider name="Attachment" rules="required|size:2048|ext:png,jpeg,jpg,pdf">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="attachment"
                                                slot-scope="{ errors }">

                                                <template v-slot:label>
                                                    {{ $t('globalTrans.attachment') }} <span class="text-danger">*</span>
                                                </template>

                                                <b-form-file
                                                    v-model="taskReport.attachment"
                                                    placeholder="Choose a file or drop it here..."
                                                    drop-placeholder="Drop file here..."
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                                <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2" @click="checkFile">{{ saveBtnName }}</b-button>
                                        &nbsp;
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('task-report')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </b-col>
                </b-row>
            </b-overlay>
        </template>
    </iq-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { taskReportStore, taskReportUpdate } from '@/modules/irrigation-scheme-service/task/api/routes'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { Common, Repository } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import { core } from '@/config/pluginInit'

export default {
    mixins: [Common],
    props: {
        taskId: {
            required: true,
            type: Number
        }
    },
    components: {
    ValidationObserver,
    ValidationProvider
    },
    data () {
        return {
            saveBtnName: this.taskId ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            taskReport: {
                complete_type_id: '',
                attachment: [],
                note: '',
                note_bn: '',
                task_date: '',
                task_id: this.taskId
            },
            defaultNull: ''
        }
    },
    computed: {
        task_status_list () {
            return this.$store.state.IrriTask.taskReportStatusList
        }
    },
    methods: {
        checkFile () {
            if (this.taskReport.attachment.length === 0) {
                alert('Please Add Attachment!')
            }
        },
        async saveTaskReport () {
            const result = await Repository.create({
                data: this.taskReport,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: taskReportStore,
                updateAPI: taskReportUpdate,
                itemId: this.id,
                modalId: 'task-report'
            })

            if (result.success) {
                this.$emit('submitted')
            }
        }
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    }
}
</script>

<style scoped>

</style>
