<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('irrigation_task.task_calendar') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes" @returned-data="searchData"/>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('irrigation_task.task_calendar') + ' ' + $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + 1) }}
                                        </template>
                                        <template v-slot:cell(id)="data">
                                            <a href="#">#{{data.item.id}}</a>
                                        </template>
                                        <template v-slot:cell(complete_type_id)="data">
                                            {{ getCompleteStatus(data.item.complete_type_id) }}
                                        </template>
                                        <template v-slot:cell(is_verified)="data">
                                            <i v-if="data.item.is_verified" style="color: #609513" class="fas fa-check-circle"></i>
                                            <i v-else style="color: #f3523f" class="fas fa-times-circle"></i>
                                        </template>
                                        <template #cell(task_assignment_attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download>Link</a>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(task_assignment_note)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_assignment_note_bn)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_review_note)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_review_note_bn)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_id)="data">
                                            <div>{{ $n(data.value) }}</div>
                                        </template>
                                        <template v-slot:cell(created_at)="data">
                                            <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFromNow }}</span>
                                        </template>
                                        <template #cell(task_calendar_date)="data">
                                            <div>{{ data.value ? dateFormat(data.value) : 'N/A' }}</div>
                                        </template>
                                        <template #cell(schedule)="data">
                                            <span v-if="data.item.task_from">From: {{ data.item.task_from }}</span><br>
                                            <span v-if="data.item.task_to">To: {{ data.item.task_to }}</span>
                                            <span class="text-muted" v-else>
                                                N/A
                                            </span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button variant="outline-success" size="sm" @click="view(data.item)">View</b-button> -->
                                            <!-- <b-button v-if="!data.item.task_task_reports_note" variant="outline-success" size="sm" @click="taskReportModal(data.item)">Task Report</b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View" @click="view(data.item)"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Task Report" v-if="!data.item.task_task_reports_note" @click="taskReportModal(data.item)"><i class="ri-file-chart-2-fill"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="dataChange"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="task-report" size="lg"
                 :hide-footer=true
                 :title="formTitle"
                 :ok-title="$t('globalTrans.close')"
                 ok-only ok-variant="danger">
            <TaskReportForm :taskId="taskId" @submitted="dataChange"/>
        </b-modal>
        <b-modal id="item-show" size="lg" :title="taskCalendarDetails" :hide-footer=true>
            <b-overlay :show="loading">
                <item-view :prop-data="itemsToView" />
            </b-overlay>
        </b-modal>
    </b-container>
</template>
<script>
import TaskReportForm from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { taskCalendarList } from '../../api/routes'
import { List, Common, helpers } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'
import { dateFormat } from '@/Utils/fliter'
import ItemView from '@/components/structure/itemView'

export default {
    mixins: [List, Common],
    components: {
        TaskReportForm, FormSearch, ItemView
    },
    data () {
        return {
            taskCalendarDetails: this.$t('irrigation_task.task_calendar'),
            loading: false,
            taskId: null,
            search: {
                task_id: '',
                task_name: '',
                is_verified: '',
                task_note: '',
                review_note: ''
            },
            balance_after_refund: '',
            testId: 0,
            rows: [],
            taskAssignmentAttachmentPath: irriSchemeServiceBaseUrl + 'public/uploads/task-assign-tasks/original/'
        }
    },
    computed: {
    isBn () {
        return this.$i18n.locale === 'bn'
    },
    taskInfo () {
        return this.$store.state.IrriTask.taskInfo
    },
    itemsToView () {
    return {
            title: '',
            data: [
                {
                    title: this.$t('irrigation_task.task_calendar_details'),
                    type: 'table',
                    data: [
                        {
                            title1: this.$t('irrigation_task.task_id'),
                            value1: this.$n(this.taskInfo.task_id),
                            title2: this.$t('irrigation_task.task_name'),
                            value2: this.isBn ? this.taskInfo.task_name_bn : this.taskInfo.task_name,
                            style: {
                                title2: 'max-width: 150px;overflow: auto;overflow-wrap: anywhere;'
                            }
                        },
                        {
                            title1: this.$t('irrigation_task.assign_user_id'),
                            value1: this.$n(this.taskInfo.assign_user_id),
                            title2: this.$t('irrigation_task.responsible'),
                            value2: this.taskInfo.assign_username
                        },
                        {
                            /* title1: this.$t('irrigation_task.schedule'),
                            value1: this.taskInfo.schedule, */
                            title1: this.$t('irrigation_task.task_assignment_attachment'),
                            value1: this.getAttachmentLink(this.taskAssignmentAttachmentPath, this.taskInfo.task_assignment_attachment),
                            config: {
                                value1: {
                                    type: 'html'
                                }
                            },
                            colSpan: {
                                value1: 3
                            }
                        },
                        {
                            title1: this.$t('irrigation_task.task_assignment_note'),
                            value1: this.isBn ? this.taskInfo.task_assignment_note_bn : this.taskInfo.task_assignment_note,
                            title2: this.$t('irrigation_task.task_assignment_status'),
                            value2: this.status(!this.taskInfo.task_assignment_status),
                            style: {
                                value1: 'max-width: 150px;overflow: auto;overflow-wrap: anywhere;'
                            }
                        },
                        {
                            title1: this.$t('irrigation_task.task_calendar_date'),
                            value1: this.taskInfo.task_calendar_date ? dateFormat(this.taskInfo.task_calendar_date) : 'N/A',
                            title2: this.$t('irrigation_task.verified'),
                            value2: this.taskInfo.is_verified ? this.$t('globalTrans.yes') : this.$t('globalTrans.no')
                        },
                        {

                            title1: this.$t('irrigation_task.task_review_note'),
                            value1: this.taskInfo.task_review_note ? (this.isBn ? this.taskInfo.task_review_note_bn : this.taskInfo.task_review_note) : 'N/A',
                            title2: this.$t('irrigation_task.task_report_note'),
                            value2: this.taskInfo.task_task_reports_note ? (this.isBn ? this.taskInfo.task_task_reports_note_bn : this.taskInfo.task_task_reports_note) : 'N/A',
                            style: {
                                value1: 'max-width: 150px;overflow: auto;overflow-wrap: anywhere;',
                                value2: 'max-width: 150px;overflow: auto;overflow-wrap: anywhere;'
                            }
                        }
                    ]
                }
            ]
        }
    },
    searchAttributes () {
        return {
            buttonText: this.$t('globalTrans.search'),
            data: [
                {
                    name: 'task_id',
                    type: 'Input',
                    label: 'irrigation_task.task_id',
                    labelCol: 3,
                    lg: 4,
                    md: 6,
                    sm: 12
                },
                {
                    name: 'task_name',
                    type: 'Input',
                    label: 'irrigation_task.task_name',
                    labelCol: 3,
                    lg: 4,
                    md: 6,
                    sm: 12
                },
                {
                    name: 'is_verified',
                    type: 'Checkbox',
                    label: 'globalTrans.verified',
                    labelCol: 2,
                    lg: 4,
                    md: 6,
                    sm: 12
                },
                {
                    name: 'task_note',
                    type: 'Input',
                    label: 'irrigation_task.note',
                    labelCol: 3,
                    lg: 4,
                    sm: 6
                },
                {
                    name: 'review_note',
                    type: 'Input',
                    label: 'irrigation_task.review_note',
                    labelCol: 3,
                    lg: 4,
                    md: 6,
                    sm: 12
                }
            ]
        }
    },
    formTitle () {
        return (this.testId === 0) ? this.$t('irrigation_task.task_report') + ' ' + this.$t('globalTrans.entry')
            : this.$t('irrigation_task.task_report') + ' ' + this.$t('globalTrans.modify')
    },
    listData () {
        return this.$store.state.IrriTask.taskCalendarList
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('irrigation_task.task_id'), class: 'text-center' },
            { label: this.$t('irrigation_task.task_name'), class: 'text-center' },
            { label: this.$t('globalTrans.schedule'), class: 'text-center' },
            { label: this.$t('globalTrans.attachment'), class: 'text-center' },
            { label: this.$t('irrigation_task.note'), class: 'text-center' },
            { label: this.$t('irrigation_task.review_note'), class: 'text-center' },
            { label: this.$t('irrigation_task.task_report'), class: 'text-center' },
            { label: this.$t('irrigation_task.task_calendar_date'), class: 'text-center' },
            { label: this.$t('pump_install.created_date'), class: 'text-center' },
            { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
        ]

        var keys = [
            { key: 'index' },
            { key: 'task_id' },
            { key: 'task_name' },
            { key: 'schedule' },
            { key: 'task_assignment_attachment' },
            { key: 'task_assignment_note' },
            { key: 'task_review_note' },
            { key: 'task_task_reports_note_small' },
            { key: 'task_calendar_date' },
            { key: 'created_at' },
            { key: 'complete_type_id' },
            { key: 'action' }
        ]

        if (this.$i18n.locale === 'bn') {
            keys[2] = { key: 'task_name_bn' }
            keys[5] = { key: 'task_assignment_note_bn' }
            keys[6] = { key: 'task_review_note_bn' }
            keys[7] = { key: 'task_task_reports_note_bn_small' }
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
    },
    watch: {
        loadingState (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    async created () {
        this.$store.dispatch('IrriTask/addTaskInfo', {})
        this.loadData()
    },
    methods: {
        getCompleteStatus (status) {
            let returnStatus = this.isBn ? 'বিচারাধীন' : 'Pending'
            if (status) {
                const statusList = Object.freeze(this.$store.state.IrriTask.taskReportStatusList)
                const foundStatus = statusList.find(el => el.value === status)
                if (foundStatus) {
                    returnStatus = this.isBn ? foundStatus.text_bn : foundStatus.text_en
                }
            }
            return returnStatus
        },
        getAttachmentLink (path, name) {
            return `<a href="${path + name}" target="_blank" download>${this.$t('globalTrans.link')}</a>`
        },
        status (condition) {
            return condition ? this.$t('globalTrans.active') : this.$t('globalTrans.inactive')
        },
        dateFormat (date) {
          return dateFormat(date)
        },
        view (item) {
            this.loading = true
            this.$bvModal.show('item-show')
            RestApi.getData(irriSchemeServiceBaseUrl, `${taskCalendarList}/${item.task_id}`)
            .then((response) => {
                if (response.success) {
                    const data = response.data[0] ? response.data[0] : null
                    if (data) {
                        this.$store.dispatch('IrriTask/addTaskInfo', data)
                    }
                }
                this.loading = false
            })
        },
        dataChange () {
            this.loadData()
        },
        taskReportModal (item) {
            this.taskId = item.task_id
            this.$bvModal.show('task-report')
        },
        default () {
            return {
                id: this.rows.length,
                editable: false
            }
        },
        async searchData (payload) {
            this.search = payload
            await this.loadData()
        },
        async loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            await RestApi.getData(irriSchemeServiceBaseUrl, taskCalendarList, params).then(async (response) => {
                if (response.success) {
                    this.dataList(response).then((res) => {
                        this.$store.commit('IrriTask/taskCalendarList', res)
                    })
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }).catch((error) => {
                if (error.response) {
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        },
        async dataList (response) {
            // Formatted object to fit in helper-function
            let listData = {}
            const filePathPrefix = irriSchemeServiceBaseUrl + 'uploads/task-assign-tasks/original/'

            listData = response.data.data.map(item => {
                item.task_assignment_attachment = item.task_assignment_attachment
                        ? filePathPrefix + item.task_assignment_attachment : null
                item.task_assignment_note = helpers.stringLimit(item.task_assignment_note, 10)
                item.task_assignment_note_bn = helpers.stringLimit(item.task_assignment_note_bn, 10)
                item.task_review_note = helpers.stringLimit(item.task_review_note, 10)
                item.task_review_note_bn = helpers.stringLimit(item.task_review_note_bn, 10)
                item.task_task_reports_note_small = helpers.stringLimit(item.task_task_reports_note, 10)
                item.task_task_reports_note_bn_small = helpers.stringLimit(item.task_task_reports_note_bn, 10)
                return item
            })
            this.pagination = response.data
            return listData
        }
    }
}
</script>
